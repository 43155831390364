// Dependencies
import React, { useState } from "react";

const FilterSelectionValueContext = React.createContext([]);

const FilterSelectionValueProvider = props => {
  const [keyValue, setKeyValue] = useState("Wszystkie bronie");
  return (
    <FilterSelectionValueContext.Provider value={[keyValue, setKeyValue]}>
      {props.children}
    </FilterSelectionValueContext.Provider>
  );
};

export { FilterSelectionValueProvider, FilterSelectionValueContext };
