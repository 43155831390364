// Dependencies
import React from "react";
import styled from "styled-components";
import { Link, useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import Img from "gatsby-image";

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fixed(width: 138, height: 55) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <StyledLink to="/">
      <StyledLogo fixed={data.file.childImageSharp.fixed} />
    </StyledLink>
  );
};

export default Logo;

const StyledLogo = styled(Img)`
  height: 55px;
`;

const StyledLink = styled(Link)`
  padding: 12px;
  outline: none;
`;
