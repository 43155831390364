// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-weapon-tsx": () => import("./../src/templates/weapon.tsx" /* webpackChunkName: "component---src-templates-weapon-tsx" */),
  "component---src-templates-course-tsx": () => import("./../src/templates/course.tsx" /* webpackChunkName: "component---src-templates-course-tsx" */),
  "component---src-templates-markdown-page-tsx": () => import("./../src/templates/markdownPage.tsx" /* webpackChunkName: "component---src-templates-markdown-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-arsenal-tsx": () => import("./../src/pages/arsenal.tsx" /* webpackChunkName: "component---src-pages-arsenal-tsx" */),
  "component---src-pages-arsenal-weapons-list-tsx": () => import("./../src/pages/arsenal/weapons_list.tsx" /* webpackChunkName: "component---src-pages-arsenal-weapons-list-tsx" */),
  "component---src-pages-cennik-tsx": () => import("./../src/pages/cennik.tsx" /* webpackChunkName: "component---src-pages-cennik-tsx" */),
  "component---src-pages-dlafirm-tsx": () => import("./../src/pages/dlafirm.tsx" /* webpackChunkName: "component---src-pages-dlafirm-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jak-dojechac-tsx": () => import("./../src/pages/jakDojechac.tsx" /* webpackChunkName: "component---src-pages-jak-dojechac-tsx" */),
  "component---src-pages-pakiety-tsx": () => import("./../src/pages/pakiety.tsx" /* webpackChunkName: "component---src-pages-pakiety-tsx" */),
  "component---src-pages-szkolenia-tsx": () => import("./../src/pages/szkolenia.tsx" /* webpackChunkName: "component---src-pages-szkolenia-tsx" */)
}

