// Dependencies
import { Link } from "gatsby";
import React, { useContext } from "react";
import styled from "styled-components";

// Components
import { FilterSelectionContext } from "./filterSelectionContext";
import Logo from "./logo";

export const navItems = [
  {
    id: 1,
    name: "O Strzelnicy",
    ref: "about/"
  },
  // {
  //   id: 2,
  //   name: "Pakiety Rekreacyjne",
  //   ref: "pakiety/"
  // },
  {
    id : 2,
    name : "Cennik",
    ref : "cennik/"
  },
  {
    id: 3,
    name: "Szkolenia",
    ref: "szkolenia/"
  },
  {
    id: 4,
    name: "Arsenał",
    ref: "arsenal/"
  },
  {
    id: 5,
    name: "Jak dojechać",
    ref: "jakDojechac/"
  }
];

interface Props {}

const Navigation: React.FC<Props> = () => {
  const [, setActive] = useContext(FilterSelectionContext);

  return (
    <StyledNav>
      <NaviList>
        <Logo />
        {navItems.map(navItem => {
          return (
            <NaviListItem key={navItem.id}>
              <NaviListLink
                to={`/${navItem.ref}`}
                partiallyActive={true}
                activeClassName="active"
                onClick={
                  navItem.ref === "arsenal/" ? () => setActive("") : () => {}
                }
              >
                {navItem.name}
              </NaviListLink>
            </NaviListItem>
          );
        })}
      </NaviList>
    </StyledNav>
  );
};

export default Navigation;

const StyledNav = styled.nav`
  height: 64px;
  margin-right: -40px;
  width: 100%;
`;

const NaviList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
const NaviListItem = styled.li`
  display: inline-block;
  cursor: pointer;
  margin: 0;
  height: 100%;
`;

const NaviListLink = styled(Link)`
  outline: none;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  transition: 0.3s ease;
  font-size: ${props => props.theme.fontSizes[1]}px;
  font-weight: 500;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: inherit;
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 0;
    transition: 0.3s ease;
    background: ${props => props.theme.colors.primary};
  }
  &.active {
    color: ${props => props.theme.colors.primary};
    &:after {
      width: 100%;
    }
  }
`;
