// Dependencies
import React from "react";
import styled from "styled-components";

interface Props {
  onCloseHandler(): void;
}

const Close: React.FC<Props> = ({ onCloseHandler }) => {
  return (
    <CloseLink onClick={() => onCloseHandler()}>
      <StyledSvg
        width="23px"
        height="23px"
        version="1.1"
        id="Warstwa_1"
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
      >
        <g>
          <path
            fill="#FFFFFF"
            d="M28.941,31.786L0.613,60.114c-0.787,0.786-0.787,2.062,0,2.849c0.393,0.395,0.909,0.59,1.424,0.59
		c0.516,0,1.031-0.195,1.424-0.59l28.541-28.541l28.541,28.541c0.395,0.395,0.909,0.59,1.424,0.59c0.516,0,1.031-0.195,1.424-0.59
		c0.787-0.787,0.787-2.063,0-2.849L35.064,31.786L63.41,3.438c0.787-0.787,0.787-2.062,0-2.849c-0.787-0.786-2.063-0.786-2.848,0
		L32.003,29.15L3.441,0.59c-0.787-0.786-2.061-0.786-2.848,0c-0.787,0.787-0.787,2.062,0,2.849L28.941,31.786z"
          />
        </g>
      </StyledSvg>
    </CloseLink>
  );
};

export default Close;

const StyledSvg = styled.svg`
  pointer-events: none;
`;
const CloseLink = styled.a`
  display: flex;
  justify-content: center;
  width: 54px;
  padding: 12px;
`;
