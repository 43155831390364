// Dependencies
import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Waypoint } from "react-waypoint";

// Components
import Navigation from "./navigation";
import Logo from "./logo";
import Hamburger from "./hamburger";
import theme from "../theme";
import MobileNavigation from "./mobileNavigation";

interface Props {}

const Header: React.FC<Props> = () => {
  const [mobileMenuVisibility, setMobileMenuVisibility] = useState(false);
  const hamburgerHandler = (): void => {
    setMobileMenuVisibility(true);
    setDisableScroll(true);
  };
  const onCloseHandler = (): void => {
    setMobileMenuVisibility(false);
    setDisableScroll(false);
  };
  const [disableScroll, setDisableScroll] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [fixed, setFixed] = useState(false);
  useEffect(() => {
    const sizeCheck = () => {
      window.innerWidth > theme.mobileBreakpoint
        ? setMobileMenu(false)
        : setMobileMenu(true);
    };
    sizeCheck();
    window.addEventListener("resize", sizeCheck);
  }, []);

  useEffect(() => {
    const target = document.querySelector("body");
    disableScroll ? disableBodyScroll(target) : enableBodyScroll(target);
  }, [disableScroll]);

  return (
    <Fragment>
      <WaypoinContainer>
        <Waypoint
          scrollableAncestor="window"
          fireOnRapidScroll
          onLeave={() => setFixed(true)}
          onEnter={() => setFixed(false)}
        />
      </WaypoinContainer>
      <HeaderContainer fixedNavbar={fixed}>
        <StyledContainer>
          {mobileMenu ? (
            <>
              <Logo />
              <Hamburger hamburgerHandler={hamburgerHandler} />
            </>
          ) : (
            <Navigation></Navigation>
          )}
          {mobileMenuVisibility ? (
            <MobileNavigation onCloseHandler={onCloseHandler} />
          ) : null}
        </StyledContainer>
      </HeaderContainer>
    </Fragment>
  );
};

export default Header;

export const HeaderContainer = styled.header<{ fixedNavbar: Boolean }>`
  background-color: ${(props) => props.theme.colors.header};
  width: 100%;
  position: sticky !important;
  top: 58px;
  left: 0;
  z-index: 100;
  transform: translateY(-58px);
`;
const WaypoinContainer = styled.div`
  position: absolute;
  top: 24px;
  left: 0;
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  max-width: ${(props) => props.theme.headerWidth}px;
  margin: 0 auto;
  padding: 0 ${(props) => props.theme.space[2]}px;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: 0 ${(props) => props.theme.space[4]}px;
  }
`;
