const React = require("react");
const {
  FilterSelectionProvider
} = require("./src/components/filterSelectionContext");

const {
  FilterSelectionValueProvider
} = require("./src/components/filterSelectionValueContext");

exports.wrapRootElement = ({ element }) => {
  return (
    <FilterSelectionValueProvider>
      <FilterSelectionProvider>{element}</FilterSelectionProvider>
    </FilterSelectionValueProvider>
  );
};

exports.onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`);
  }
};
