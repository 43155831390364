// Dependencies
import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "gatsby";

// Components
import Close from "./close";
import { navItems } from "./navigation";
import { StyledContainer } from "./header";
import { FilterSelectionContext } from "./filterSelectionContext";
import Logo from "./logo";

interface Props {
  onCloseHandler(): void;
}

const MobileNavigation: React.FC<Props> = ({ onCloseHandler }) => {
  const [, setActive] = useContext(FilterSelectionContext);

  const arsenalHandler = () => {
    setActive("");
    onCloseHandler();
  };
  return (
    <Mask>
      <HeaderContainer>
        <StyledContainer>
          <span />
          <Close onCloseHandler={onCloseHandler} />
        </StyledContainer>
      </HeaderContainer>
      <NavListMobile>
        <NavListItemMobile onClick={() => onCloseHandler()}>
          <Logo />
        </NavListItemMobile>
        {navItems.map(navItem => {
          return (
            <NavListItemMobile
              onClick={() => {
                navItem.ref === "arsenal/"
                  ? arsenalHandler()
                  : onCloseHandler();
              }}
              key={navItem.id}
            >
              <NavLinkMobile to={`/${navItem.ref}`}>
                {navItem.name}
              </NavLinkMobile>
            </NavListItemMobile>
          );
        })}
      </NavListMobile>
    </Mask>
  );
};

export default MobileNavigation;

const NavListMobile = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 100%;
`;

const FadeIn = keyframes`
0% {
  opacity : 0;
}
100% {
  opacity : 1;
}
`;

const NavListItemMobile = styled.li`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.colors.white};
  z-index: 5;

  font-weight: 700;
  margin-bottom: ${props => props.theme.space[5]}px;
  animation: ${FadeIn} 0.2s ease forwards;
  opacity: 0;
  animation-delay: 0.2s;
`;

const NavLinkMobile = styled(Link)`
  text-decoration: none;
  font-size: ${props => props.theme.fontSizes[5]}px;
  width: 100%;
  padding: 12px 0;
  text-align: center;
`;

const MaksIn = keyframes`
0% {
  transform : translateX(-50%);
  opacity : 0;
}
100% {
  transform : translateX(0);
  opacity : 1;
}
`;

const Mask = styled.div`
  background-color: ${props => props.theme.colors.header};
  position: fixed;
  top: -24px;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100vh + 24px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: ${MaksIn} 0.2s ease-out forwards;
`;

const HeaderContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 24px;
  left: 0;
`;
