// Dependencies
import React from 'react';
import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';

// Components
import Logo from './logo';
import Facebook from './svg/facebook';
import Instagram from './svg/instagram';

interface Props {}

const Footer: React.FC<Props> = () => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        footer(where: { id: "cla2jr5sybyg50btgkvipdzug" }) {
          id
          city
          contactNumber
          contactNumber1
          email
          name
          nip
          street
          fullCompanyName
          websiteAddress
        }
      }
    }
  `);
  const {
    city,
    contactNumber,
    contactNumber1,
    email,
    name,
    fullCompanyName,
    nip,
    street,
    websiteAddress,
  } = data.cms.footer;
  return (
    <StyledFooter id="contact">
      <FooterContainer>
        <ColumnWrapper>
          <FooterColumn>
            <LogosWrapper>
              <Logo />
            </LogosWrapper>
            <FooterContent>
              <p>{name}</p>
              <p>{fullCompanyName}</p>
              <p>{street}</p>
              <p>{city}</p>
              <p>{nip}</p>
            </FooterContent>
          </FooterColumn>
          <FooterColumn>
            <LogosWrapper>
              <a href="https://www.facebook.com/strzelnicaB7">
                <Facebook />
              </a>
              <a href="https://www.instagram.com/strzelnicaB7">
                <Instagram />
              </a>
            </LogosWrapper>
            <FooterContent>
              <p>{contactNumber}</p>
              <p>{contactNumber1}</p>
              <p>{websiteAddress}</p>
              <p>{email}</p>
            </FooterContent>
          </FooterColumn>
        </ColumnWrapper>

        <FooterLinks>
          <Link to="/regulamin">Regulamin</Link>
        </FooterLinks>
      </FooterContainer>
    </StyledFooter>
  );
};

export default Footer;
const StyledFooter = styled.footer`
  background-color: ${(props) => props.theme.colors.greyBg};
  border-top: 8px solid ${(props) => props.theme.colors.primary};
`;

const FooterContainer = styled.div`
  max-width: ${(props) => props.theme.containerWidth}px;
  margin: 0 auto;
  padding: ${(props) => props.theme.space[5]}px
    ${(props) => props.theme.space[2]}px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: ${(props) => props.theme.space[7]}px
      ${(props) => props.theme.space[4]}px;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    flex-direction: row;
  }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.space[6]}px;
`;

const LogosWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.space[4]}px;
  display: flex;
  align-items: center;
  height: 56px;
  justify-content: center;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    justify-content: flex-start;
    margin-bottom: ${(props) => props.theme.space[5]}px;
  }
`;
const FooterContent = styled.div`
  p {
    color: ${(props) => props.theme.colors.white} !important;
    margin: 0 0 0.1rem 0;
    text-align: center;
    font-size: ${(props) => props.theme.fontSizes[2]}px;
    text-decoration: none !important;
    @media screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
      text-align: left;
      font-size: ${(props) => props.theme.fontSizes[4]}px;
    }
  }
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    flex-direction: row;
  }
  a {
    margin-bottom: ${(props) => props.theme.space[2]}px;
    text-align: center;
    @media screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
      text-align: left;
      margin-right: ${(props) => props.theme.space[7]}px;
    }
  }
`;
