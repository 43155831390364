// Dependencies
import React from "react";
import styled from "styled-components";

interface Props {
  hamburgerHandler(): void;
}

const Hamburger: React.FC<Props> = ({ hamburgerHandler }) => {
  return (
    <HamburgerButton onClick={hamburgerHandler}>
      <Slice />
      <Slice />
      <Slice />
    </HamburgerButton>
  );
};

export default Hamburger;

const HamburgerButton = styled.a`
  width: 64px;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 19px 19px 19px 29px;
`;

const Slice = styled.span`
  height: 2px;
  width: 30px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 4px;
`;
