// Dependencies
import React, { useState } from "react";

const FilterSelectionContext = React.createContext([]);

const FilterSelectionProvider = props => {
  const [active, setActive] = useState("");
  return (
    <FilterSelectionContext.Provider value={[active, setActive]}>
      {props.children}
    </FilterSelectionContext.Provider>
  );
};

export { FilterSelectionContext, FilterSelectionProvider };
