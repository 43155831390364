const theme = {
  mobileBreakpoint: 988,
  breakpoints: ["420px", "576px", "786px", "998px"],
  colors: {
    primary: "#e27100",
    white: "#fff",
    header: "rgba(0, 0, 0, 0.96)",
    grey: "#414141",
    greyBg: "#292928",
    greyLink: "#979797",
    greyBar: "#6d6d6d",
    black: "rgb(0, 0, 0)"
  },
  space: [0, 4, 8, 16, 24, 32, 40, 48, 64, 80, 128, 256],
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 36, 40, 48, 54, 64],
  containerWidth: 988,
  headerWidth: 988,
  footerWidth: 700
};

export default theme;
