// Dependencies
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled, { ThemeProvider } from "styled-components";
import CookieConsent from "react-cookie-consent";

// Components
import Header from "./header";
import "./layout.css";
import theme from "../theme";
import Footer from "./footer";
require("typeface-russo-one");
require("typeface-roboto");

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <ThemeProvider {...{ theme }}>
      <Body>
        <div>
          <Spacer />
          <Header />
          <main>{children}</main>
        </div>
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Nie pokazuj więcej tego powiadomienia."
          cookieName="gatsby-gdpr-google-analytics"
          buttonStyle={{
            color: "#e27100",
            backgroundColor: "#101010",
            fontSize: "12px",
            justifySelf: "flex-start",
            padding: "0px",
            margin: "15px",
            outline: "none",
          }}
          style={{
            color: "#C8C8C8",
            backgroundColor: "#101010",
            fontSize: "12px",
            justifyContent: "flex-start",
          }}
        >
          Strona Strzelnicy B7 korzysta z plików cookies. Strzelamy, że nie masz
          nic przeciwko - w innym przypadku zmień ustawienia swojej
          przeglądarki!
        </CookieConsent>
      </Body>
    </ThemeProvider>
  );
};

export default Layout;

const Body = styled.div`
  /* overflow: hidden; */
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: ${(props) => props.theme.colors.white};
`;

const Spacer = styled.div`
  width: 100%;
  height: 42px;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    height: 82px;
  }
`;
