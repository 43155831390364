import React from "react";
import styled from "styled-components";

const Instagram = () => {
  return (
    <StyledSVG
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.0627 13.7906C18.0627 16.1613 16.107 18.0897 13.7027 18.0897H6.22844C3.8243 18.0897 1.86852 16.1613 1.86852 13.7906V6.42081C1.86852 4.05018 3.8243 2.12171 6.22844 2.12171H13.7027C16.107 2.12171 18.0627 4.05018 18.0627 6.42081V13.7906ZM13.7027 0.279297H6.22844C2.78906 0.279297 0 3.02948 0 6.42081V13.7906C0 17.182 2.78906 19.9322 6.22844 19.9322H13.7027C17.1421 19.9322 19.9312 17.182 19.9312 13.7906V6.42081C19.9312 3.02948 17.1421 0.279297 13.7027 0.279297V0.279297Z" />
      <path d="M9.96719 13.1765C8.25062 13.1765 6.85297 11.7984 6.85297 10.1058C6.85297 8.4119 8.25062 7.035 9.96719 7.035C11.6838 7.035 13.0814 8.4119 13.0814 10.1058C13.0814 11.7984 11.6838 13.1765 9.96719 13.1765M9.96719 5.1925C7.21539 5.1925 4.98438 7.39244 4.98438 10.1058C4.98438 12.8191 7.21539 15.019 9.96719 15.019C12.719 15.019 14.95 12.8191 14.95 10.1058C14.95 7.39244 12.719 5.1925 9.96719 5.1925" />
      <path d="M15.3202 4.16931C15.6869 4.16931 15.9841 4.46243 15.9841 4.82402C15.9841 5.18554 15.6869 5.47865 15.3202 5.47865C14.9535 5.47865 14.6562 5.18554 14.6562 4.82402C14.6562 4.46243 14.9535 4.16931 15.3202 4.16931" />
    </StyledSVG>
  );
};

export default Instagram;

const StyledSVG = styled.svg`
  width: 48px;
  height: 48px;
  fill: #ae541e;
`;
