import React from "react";
import styled from "styled-components";

const Facebook = () => {
  return (
    <StyledSVG
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.50364 3.36095C2.50364 3.79828 2.50364 5.75028 2.50364 5.75028H0.601562V8.67196H2.50364V17.3542H6.4109V8.6722H9.03285C9.03285 8.6722 9.27841 7.27128 9.39744 5.73951C9.05618 5.73951 6.42567 5.73951 6.42567 5.73951C6.42567 5.73951 6.42567 4.03976 6.42567 3.74183C6.42567 3.44326 6.85168 3.04164 7.27274 3.04164C7.69301 3.04164 8.58001 3.04164 9.40156 3.04164C9.40156 2.64384 9.40156 1.26939 9.40156 0C8.30482 0 7.05708 0 6.50709 0C2.40705 -0.00020174 2.50364 2.92438 2.50364 3.36095Z" />
    </StyledSVG>
  );
};

export default Facebook;

const StyledSVG = styled.svg`
  width: 25px;
  height: 48px;
  margin-right : ${props => props.theme.space[4]}px;
  fill: #AE541E;
  /* @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    width: 52px;
    height: 52px;
    min-width: 52px;
  } */
`;
